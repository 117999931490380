@import '../../styles/index';

.base {
  [data-layout-background='white'] & {
    @include media-from(lg) {
      margin-left: -1.25rem;
      width: calc(100% + 2.5rem);
    }
  }
}
