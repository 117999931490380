@import 'src/uikit/styles/magazine-index';

.base {
  margin: 0;
  border-radius: $border-radius-large;

  @include media-from(sm) {
    border-radius: $border-radius-default;
  }
}

.wrapper {
  padding-bottom: 2rem;

  @include media-from(sm) {
    padding-bottom: 5rem;
  }
}

.simple {
  background: $neutral-0;
  filter: drop-shadow(0px 8px 64px rgba(0, 0, 0, 0.12));
}

.highlighted {
  &.surrogate {
    background: $surrogate-9;
  }

  &.gradient {
    background: $gradient-enercity;
  }

  .inner::after {
    content: ' ';
    background-image: $magazine-shadow-2;
    position: absolute;
    height: 100px;
    bottom: -100px;
    width: 96%;
    left: 2%;
    z-index: 1;
    background-size: 100%;
    background-repeat: no-repeat;

    @include media-from(xl) {
      height: 200px;
      bottom: -200px;
    }
  }
}

.inner {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media-from(sm) {
    flex-direction: row;
  }
}

.content {
  padding: 2rem 1.5rem 0 1.5rem;

  @include media-from(sm) {
    padding: 3rem 0 3rem 3rem;
    width: 60%;
  }
}
