@import '../../../styles/index';

.button {
  margin-top: 1.5rem;
  width: 100%;

  @include media-from(sm) {
    margin-top: 2rem;
    width: auto;
  }
}
