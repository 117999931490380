@import '../../styles/index';

.base {
  background-color: $neutral-0;
  border-radius: 3px;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.1),
    0 0 27px 0 rgba(0, 0, 0, 0.12);
  color: $neutral-12;
  display: flex;
  height: 580px;

  @include media-to(md) {
    flex-direction: column;
    height: auto;
  }

  .image {
    flex-basis: 844px;
    flex-shrink: 0;

    @include media-to(lg) {
      flex-basis: 660px;
    }

    @include media-to(md) {
      flex-basis: 484px;
    }

    @include media-to(sm) {
      flex-basis: auto;
      height: 213px;
    }

    img {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .informations {
    display: flex;
    flex-direction: column;
    padding: 40px 32px 32px 32px;
    position: relative;

    @include media-to(md) {
      padding: 1.5rem;
    }

    @include media-to(sm) {
      padding: 1rem;
    }

    .category {
      color: $surrogate-9;
      font-size: $font-size-20;
      font-weight: $font-weight-medium;
      line-height: 1.75rem;
      margin-bottom: 0.5rem;

      @include media-to(md) {
        font-size: 1.125rem;
        line-height: 1.2;
        margin-bottom: 0.75rem;
      }
    }

    .headline {
      font-size: $font-size-30;
      font-weight: $font-weight-bold;
      line-height: 2.3438rem;
      margin-bottom: 1.5rem;

      @include media-to(md) {
        font-size: $font-size-24;
        line-height: 1.3;
      }

      @include media-to(sm) {
        font-size: 1.125rem;
      }
    }

    .text {
      display: -webkit-box;
      font-size: $font-size-18;
      font-weight: $font-weight-light;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-to(md) {
        -webkit-line-clamp: 3;
      }

      @include media-to(sm) {
        display: none;
      }
    }

    .more-button {
      margin-top: 19px;

      @include media-to(sm) {
        display: none;
      }
    }

    .date {
      color: $neutral-9;
      font-size: $font-size-18;
      font-weight: 300;
      margin-top: auto;
      padding-top: 1.5rem;

      @include media-to(sm) {
        font-size: 0.875rem;
        margin-top: 1.5rem;
        position: relative;
      }
    }
  }
}
