@import '../../styles/index';

.base {
  background-color: $neutral-0;
  border-radius: 3px;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.1),
    0 0 27px 0 rgba(0, 0, 0, 0.12);
  color: $neutral-12;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include media-to(sm) {
    min-height: unset;
  }

  .image {
    height: 254px;

    @include media-to(sm) {
      height: unset;
    }

    img {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      object-fit: cover;
      width: 100%;
    }
  }

  .informations {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.5rem;

    @include media-to(sm) {
      padding: 1rem;
    }

    .category {
      color: $surrogate-9;
      font-size: $font-size-18;
      font-weight: $font-weight-medium;
      line-height: 28px;
      margin-bottom: 0.5rem;

      @include media-to(sm) {
        font-size: 0.875rem;
        line-height: 1.2;
      }
    }

    .headline {
      font-size: $font-size-24;
      font-weight: $font-weight-bold;
      line-height: 31.2px;
      margin-bottom: 1rem;
      word-break: break-word;

      @include media-to(sm) {
        display: -webkit-box;
        font-size: 1.125rem;
        line-height: 1.3;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .text {
      display: -webkit-box;
      font-size: $font-size-18;
      font-weight: $font-weight-light;
      line-height: 28px;
      line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-to(sm) {
        font-size: 18px;
        line-height: 25.6px;
        -webkit-line-clamp: 9;
        margin-top: 0.5rem;
      }
    }

    .date,
    .sub-text {
      color: $neutral-12;
      font-size: $font-size-18;
      font-weight: $font-weight-light;
      margin-top: auto;
      padding-top: 1rem;

      @include media-to(sm) {
        font-size: 0.875rem;
      }
    }
  }
}
