@import '../../styles/index';

.base {
  position: absolute;
  left: 1.25rem;
  top: 1.25rem;
  display: flex;
  flex-direction: column;
  z-index: 20;
  max-width: 25rem;
}

.with-configurator-header {
  top: 6.25rem;

  &.fixed {
    top: 1.25rem;
    position: fixed;
  }
}
