@import '../../styles/index';

.base {
  margin: 0;
}

.iframe {
  border: none;
  width: 100%;
  height: 80vh;
}
