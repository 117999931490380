@import '../../../styles/index';

.base {
  height: auto;
  max-height: 300px;
  max-width: 100%;
  margin: auto auto 0 auto;

  @include media-from(sm) {
    max-height: 100%;
    width: auto;
    max-width: 50%;
  }

  &.is-cover {
    object-fit: cover;
    height: 220px;
    width: 100%;

    @include media-from(sm) {
      object-fit: initial;
      height: auto;
      width: auto;
    }
  }
}
