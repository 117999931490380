@import 'colors';

$gradient-enercity: linear-gradient(
  90deg,
  #{$violet-9} 0%,
  #{$red-9} 100%
);

$gradient-enercity-netz: linear-gradient(
  120deg,
  #{$orange-9} 20%,
  #{$red-9} 40%,
  #{$violet-9} 80%
);

$gradient-enercity-netz-headline: linear-gradient(
  320deg,
  #{$orange-9} 0%,
  #{$red-9} 20%,
  #{$violet-9} 100%
);

$gradient-enercity-netz-nav-link: linear-gradient(
  270deg,
  #{$orange-9} 10%,
  #{$red-9} 50%,
  #{$violet-9} 100%
);

$gradient-enercity-netz-secondary-button: linear-gradient(
  134.05deg,
  #{$orange-9} 0%,
  #{$red-9} 60%,
  #{$violet-9} 100%
);

$gradient-hightlight: linear-gradient(
  222.95deg,
  $neutral-7 0%,
  $neutral-0 100%
);
