@import '../../styles/index';

.base {
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: $index-navswitch;
  max-width: 10.8rem;
  // workaround for old enercity.de styles - delete when fully relaunched
  font-family: 'Euclid Medium', 'Euclid Enercity', sans-serif;
}

.label {
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
  user-select: none;
  background: transparent;
  text-align: left;
  line-height: 1.375;
  position: relative;

  // this logic is for safari
  & > * {
    background: $violet-9 $gradient-enercity-netz-nav-link;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
  }
}

.icon {
  margin-left: 0.25rem;
  color: $orange-9;
  position: absolute;
  bottom: 0;
}
