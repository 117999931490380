@import '../../styles/index';

.base {
  height: 60vh;

  position: relative;

  @include media-from(sm) {
    margin-bottom: 4rem;
  }

  @include media-from(md) {
    height: 70vh;
    margin-bottom: 6rem;
  }
}

.picture {
  height: 100%;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: calc(50% - 3rem);
  transform: translateX(-50%);
  width: 100vw;
  z-index: $index-magazine-hero-content;

  @include media-from(sm) {
    top: calc(50% + 2rem);
    transform: translate(-50%, -50%);
  }
}

.elements {
  margin-top: 2rem;

  @include media-to(xsbetween) {
    padding: 0 1rem;
    width: 100%;
  }

  @include media-from(sm) {
    display: none;
  }
}
