@import '../../styles/index';

.base {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 1rem;
  z-index: $index-header;

  @include media-from(sm) {
    padding: 2.5rem;

    &.has-margin-bottom {
      margin-bottom: 4rem;
    }
  }
}

.logo {
  width: 100%;
  max-width: 5.75rem;

  @include media-from(sm) {
    max-width: 8.875rem;
  }
}

.link {
  display: flex;
  gap: 0.375rem;
  align-items: flex-start;
  font-size: $font-size-14;
  font-weight: $font-weight-medium;
  color: $surrogate-9;

  @include media-from(sm) {
    font-size: $font-size-20;
    align-items: center;
    gap: 0.5rem;
  }
}
