@import '../../styles/index';

.base {
  column-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  width: 100%;

  & > div {
    align-content: center;
    display: flex;
    flex-basis: 300px;
    flex-direction: row;
    flex-grow: 1;
    margin: 0;

    & > div {
      &:last-child {
        margin-left: 1.25rem;
        width: 100%;
      }
    }
  }
}
