@import '../../styles/index';

.main {
  display: block;
}

.no-scale {
  & svg {
    width: initial !important;
    height: initial !important;
  }
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
