@import '../../styles/index';

.headline {
  h2 {
    font-weight: $font-weight-bold;
    line-height: 1.5;
    font-size: $font-size-30;

    @include media-from(md) {
      font-size: $font-size-44;
    }
  }

  //sub text
  div {
    font-size: $font-size-20;
    line-height: 1.3;

    @include media-from(md) {
      max-width: 62.25rem;
      font-size: $font-size-24;
    }
  }
}

.section {
  background-color: initial;
}

.wrapper {
  display: flex;
  flex-direction: column;

  @include media-from(md) {
    flex-direction: row;
  }
}
