@import '../../styles/index';

.base {
  .grid {
    display: grid;
    gap: 1.5rem;
    margin: 1.5rem 0;

    @include media-from(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-from(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
