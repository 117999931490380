@import '../../styles/index';

.base {
  background: $gradient-enercity;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4rem 1rem;

  @include media-from(sm) {
    padding: 4.75rem 4.75rem 3.75rem 4.75rem;
  }
}

.logo {
  margin-bottom: 3rem;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2.5rem;
}

.social-link {
  color: $neutral-0;
  transition: transform $duration-default;

  &:hover {
    transform: translateY(-4px);
  }
}

.base-link {
  color: $neutral-0;
  font-size: $font-size-16;
  line-height: 1.25;
  font-weight: $font-weight-light;

  &:hover {
    text-decoration: underline;
  }

  @include media-from(sm) {
    font-size: $font-size-18;
    line-height: 1.3;
  }
}

.base-links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  column-gap: 1.875rem;
  row-gap: 1.25rem;
  justify-content: center;

  @include media-from(xs) {
    gap: 2rem;
    margin-bottom: 1rem;
  }
}
