@import '../../styles/index';

.layout {
  position: relative;

  // TODO: remove override styles !!!
  :global(* > * > [class~='Image-module-base']) {
    @include media-from(lg) {
      margin-left: -6.78125rem;
      max-width: 66.375rem;
      width: calc(100% + 13.5625rem) !important;

      img {
        width: 100% !important;
      }
    }
  }
}
