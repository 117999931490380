@import '../../styles/index';

.base {
  display: flex;
  margin: 0;

  @include media-to(sm) {
    align-items: center;
  }

  @include media-to(xs) {
    flex-direction: column;
  }
}

.picture {
  border-radius: calc(128px / 2);
  box-shadow: 0 24px 56px 0 rgba(0, 0, 0, 0.16);
  height: 128px;
  width: 128px;

  // this is a hack for safari, otherwise the border-radius does not show in safari
  & > picture > img {
    border-radius: calc(128px / 2);
  }

  @include media-to(sm) {
    border-radius: calc(96px / 2);
    height: 96px;
    width: 96px;
  }
}

.name {
  color: $neutral-12;
  display: block;
  font-size: 1.25rem;
  font-weight: $font-weight-medium;
  line-height: 1;

  em {
    color: $neutral-9;
    font-style: italic;
    font-weight: $font-weight-regular;
  }

  @include media-to(sm) {
    font-size: $font-size-18;
  }
}

.job-title {
  color: $neutral-9;
  display: block;
  font-size: 1.125rem;
  line-height: 1;
  margin-top: 0.25rem;

  @include media-to(sm) {
    font-size: $font-size-16;
    margin-top: 0.125rem;
  }
}

.links {
  color: $neutral-12;
  font-size: 1.125rem;
  line-height: 1;

  @include media-to(sm) {
    margin-top: 0.75rem;
  }

  .link {
    color: $surrogate-9;
    display: block;
    text-decoration: none;

    @include media-to(sm) {
      font-size: $font-size-16;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .link-mail {
    color: $surrogate-9;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
}

.person {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 1.5rem;

  @include media-to(sm) {
    margin-left: 1rem;
    margin-top: 1.25rem;
  }

  @include media-to(xsbetween) {
    margin-left: 0;
    text-align: center;
  }
}

.picture-wrapper {
  position: relative;
  @include media-to(sm) {
    width: 96px;
  }
}

.social {
  align-items: center;
  background-color: $neutral-0;
  border: 4px $neutral-0 solid;
  border-radius: 100px;
  bottom: 0;
  color: $neutral-8;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  transition: color $duration-default;
  width: 32px;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  @include media-from(sm) {
    height: 40px;
    width: 40px;
  }

  &.Twitter:hover {
    color: $color-twitter;
  }

  &.Xing:hover {
    color: $color-xing-dark;
  }

  &.Linkedin:hover {
    color: $color-linkedin;
  }
}

.social-icon {
  height: 1rem;
  width: 1rem;

  @include media-from(sm) {
    height: 1.25rem;
    width: 1.25rem;
  }
}
