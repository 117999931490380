@import '../../styles/index';

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;

  @include media-to(sm) {
    grid-template-columns: 1fr;
  }
}
