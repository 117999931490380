@import '../../styles/index';

.wrapper {
  padding-top: 4rem;
  width: min(50rem, 100%);

  @include media-from(sm) {
    padding-top: 4.5rem;
  }
}

.base {
  background-color: $neutral-0;
  border-radius: 0.5rem;
  box-shadow: $shadow--medium;
  position: relative;

  .body {
    padding: 2rem 1rem;

    @include media-from(sm) {
      padding: 3rem 3rem 2rem;
    }
  }

  .badge {
    align-items: center;
    background-color: $turquoise-9;
    border-radius: 50% 50% 50% 0%;
    color: $neutral-0;
    display: flex;
    height: 5rem;
    justify-content: center;
    position: absolute;
    right: 50%;
    top: 0;
    transform: translate(50%, calc(-4rem));
    width: 5rem;

    @include media-from(md) {
      height: 7.5rem;
      right: 0;
      transform: translate(4.5rem, -4.5rem);
      width: 7.5rem;
    }
  }

  .headline {
    margin-bottom: 1.5rem;
    text-align: center;

    @include media-from(sm) {
      margin-bottom: 2rem;
      text-align: left;
    }
  }

  .label {
    color: $neutral-12;
    font-size: $font-size-18;
    font-weight: $font-weight-medium;
    line-height: 1.5;
    text-align: left;

    @include media-from(sm) {
      font-size: $font-size-20;
    }
  }

  .subtext {
    color: $neutral-12;
    font-size: $font-size-16;
    font-weight: $font-weight-light;
    line-height: 1.5;
  }

  .savings-amount {
    font-size: $font-size-30;
    font-weight: $font-weight-medium;
    line-height: 1.2;
    @extend %text-linear-gradient;
    padding-top: 0.25rem;

    @include media-from(sm) {
      font-size: $font-size-36;
      padding-top: 0;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    @include media-from(sm) {
      gap: 0.75rem;
    }

    & > .column {
      flex: 1 1 100%;
      flex-direction: column;

      .label {
        display: block;
      }

      @include media-from(sm) {
        flex: 1;

        &:first-child {
          flex: 0 0 200px;
        }
      }
    }
  }

  .icon-card-group {
    & > div {
      display: flex;
    }
  }

  .icon-card {
    flex-direction: row;
    justify-content: normal;
    min-height: 3.75rem;
    padding: 0 0.5rem;

    & > i {
      height: 1.5rem;
      width: 1.5rem;
    }

    @include media-from(sm) {
      padding: 0;

      & > span:first-of-type {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }

  .footer {
    align-items: center;
    border-top: 1px solid $surrogate-3;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1rem;

    @include media-from(sm) {
      align-items: normal;
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 3rem;
    }
  }
}
