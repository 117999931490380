@import 'src/uikit/styles/magazine-index';

.shadow {
  width: 96%;
  left: 2%;
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;

  @include media-from(sm) {
    height: 100px;
    bottom: -100px;
  }

  @include media-from(xl) {
    height: 200px;
    bottom: -200px;
  }
}

.shadow[data-hidden='xs'] {
  @include media-from(xs) {
    display: none;
  }
}

.shadow[data-hidden='sm'] {
  @include media-from(sm) {
    display: none;
  }
}

.shadow[data-type='teaser-small'] {
  background-image: url(/assets/magazine/magazine-shadow-teaser-small.svg);
  height: 100px;
  bottom: -100px;
}

.shadow[data-type='2'] {
  background-image: url(/assets/magazine/magazine-shadow-2.svg);
}

.shadow[data-type='3'] {
  background-image: url(/assets/magazine/magazine-shadow-3.svg);
  opacity: 0.6;
}
