@use 'sass:math';
@import '../../styles/index';

$base-skew: 124px;
$skew-height: min(
  $base-skew,
  calc(#{math.div($base-skew, map-get($breakpoints, xl))} * 100vw)
);

.base {
  bottom: calc((var(--footer-offset, $skew-height) * -1) + 2.875rem);
  position: sticky;
  z-index: $index-back-to-top;

  &.is-fixed {
    bottom: 0;
    position: relative;

    .button {
      pointer-events: all;
      transform: scale(1);
    }
  }

  @include media-from(lg) {
    bottom: calc((var(--footer-offset, $skew-height) * -1) + 3.875rem);

    &.is-fixed {
      bottom: 0;
    }
  }
}

.button {
  align-items: center;
  appearance: none;
  background-color: $neutral-0;
  border-radius: 50%;
  border: none;
  box-shadow: $shadow-hoverable;
  color: $surrogate-9;
  cursor: pointer;
  display: flex;
  height: 3.75rem;
  justify-content: center;
  transform: scale(0);
  transform-origin: center;
  transition: transform $duration-default;
  width: 3.75rem;

  &.is-shown {
    pointer-events: all;
    transform: scale(1);
  }
}

.host {
  bottom: var(--footer-offset, $skew-height);
  pointer-events: none;
  position: absolute;
  right: 2rem;
  transform: translateY(50%);

  @include media-from(lg) {
    right: 4rem;
  }
}
