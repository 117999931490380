@import '../../../styles/index';

.base {
  display: flex;
  font-size: $font-size-20;
  flex-grow: 1;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 1rem;

    @include media-from(xs) {
      margin-top: 0rem;
    }
  }

  @include media-from(xs) {
    flex-direction: row;
    margin-top: 0;
  }

  dt {
    font-weight: $font-weight-medium;
  }

  dd {
    margin: 0;

    @include media-from(xs) {
      margin-left: 1.5rem;
    }
  }
}
