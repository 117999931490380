@import '../../styles/index';

.base {
  margin: 0;
}

.wrapper {
  display: grid;
  gap: 0.75rem;

  @include media-from(sm) {
    gap: 1rem;

    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-rows: 1fr;
  }
}
