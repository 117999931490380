@import '../../../styles/index';

.base {
  font-size: $font-size-20;
  display: flex;
  flex-direction: column;

  @include media-from(xs) {
    flex-direction: row;
  }
}

.date-column {
  display: flex;
  flex-direction: column;

  @include media-from(xs) {
    flex-direction: row;
  }

  &:first-child {
    @include media-from(xs) {
      margin-right: 4rem;
    }
  }

  &:last-child {
    margin-top: 1.5rem;
    @include media-from(xs) {
      margin-top: 0;
    }
  }
}

.date-headline {
  margin-right: 1.5rem;
  font-weight: $font-weight-medium;
}

.date {
  font-weight: $font-weight-light;
}
