@import '../../styles/index';

.base {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-from(md) {
    gap: 2.5rem;
    flex-direction: row;
  }
}

.image-wrapper {
  width: 100%;

  @include media-from(md) {
    max-width: 35rem;
    z-index: 2;
  }
}

.content {
  position: relative;
}

.wrapper {
  width: 100%;
  padding: 2rem 1rem;

  @include media-from(md) {
    width: calc(100% + 6.75rem);
    max-width: none;
    margin-left: -6.75rem;
    padding: 4rem 2rem 4rem 6.75rem;
  }
}

.title {
  font-size: $font-size-24;
  font-weight: $font-weight-medium;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.3;

  @include media-from(md) {
    font-size: $font-size-44;
    margin-bottom: 1.5rem;
  }
}

.description-top,
.description-bottom {
  font-size: $font-size-16;
  font-weight: $font-weight-regular;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  @include media-from(md) {
    margin-bottom: 4rem;
    font-size: $font-size-22;
  }
}

.description-bottom {
  @include media-from(md) {
    margin-bottom: 2.5rem;
  }
}

.button {
  width: 100%;

  @include media-from(sm) {
    max-width: 20rem;
  }
}

.list {
  @extend %list-checkmark;

  margin-top: 0;
  margin-bottom: 2rem;

  @include media-from(md) {
    margin-bottom: 4rem;
  }

  .list-item {
    margin-bottom: 1.5rem;
    line-height: 1.6;

    @include media-from(md) {
      &::before {
        transform: none;
        width: 2rem;
        height: 2rem;
      }

      span {
        display: inline-block;
        font-size: $font-size-22;
        padding-left: 1rem;
      }
    }
  }
}
