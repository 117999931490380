@import '../../../styles/magazine-index';

.summary-text {
  font-size: $font-size-20;
  font-weight: $font-weight-medium;
  line-height: 1.5;
}

.social {
  margin-bottom: 0.75rem;
}
