@import '../../styles/index';

.addon-wrapper {
  display: none;
  opacity: 1;
  pointer-events: all;
  transition: opacity $duration-default $duration-default;

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity $duration-default 0s;
  }

  @include media-from(lg) {
    display: flex;
  }
}
