@import '../../../styles/magazine-index';

.base {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  @include media-from(lg) {
    margin: 0 (-$magazine-grid-gap-desktop);
    width: calc(100% + #{$magazine-grid-gap-desktop * 2});
  }

  > div,
  > article,
  > a {
    padding: $magazine-grid-gap-tablet 0;
    flex: 0 1 100%;

    @include media-from(xs) {
      padding: $magazine-grid-gap-tablet;

      flex-basis: calc(100% / 2);

      &:global(.MagazineAmazingNumber-module-base),
      &:global(.magnolia-wrapper.MagazineAmazingNumber) {
        max-width: calc(100% / 2);
      }
    }

    @include media-from(md) {
      flex-basis: calc(100% / 3);

      &:global(.MagazineAmazingNumber-module-base),
      &:global(.magnolia-wrapper.MagazineAmazingNumber) {
        max-width: calc(100% / 3);
      }

      &:global(.MagazineFaq-module-base),
      &:global(.magnolia-wrapper.MagazineFaq) {
        flex-basis: auto;
      }

      &:global(.magnolia-wrapper.MagazineFaq) {
        flex-basis: 100%;
      }
    }

    @include media-from(lg) {
      padding: $magazine-grid-gap-desktop;
    }
  }
}

.wrapper {
  position: relative;
  width: 100%;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}
