@import '../../../styles/magazine-index';

.section {
  & > div:not(:last-child) {
    margin-block: $space-between-sections;

    @include media-from(sm) {
      margin-block: $space-between-sections-sm;
    }
  }
}
