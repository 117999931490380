@import '../../styles/index';

.base {
  margin: 0;
  width: 100%;
}

.iframe {
  width: 100%;
  height: 80vh;

  background-color: white;
  border-width: 0;
}
