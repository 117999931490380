@import '../../styles/index';

.base {
  path {
    stroke: currentColor;
  }

  :global(.transparent path) {
    stroke: transparent;
  }
}
