@import '../../styles/index';

.wrapper {
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box {
  margin: 0 auto;
  max-width: 620px;
  width: 100%;
  border-radius: $border-radius-default;
  background-color: $neutral-0;
  padding: 2rem 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  color: $neutral-12;

  @include media-from(xs) {
    padding: 3rem;
  }

  h1 {
    margin: 0 0 0.5rem;
    font-size: $font-size-20;
    font-weight: $font-weight-regular;
    line-height: 1.25;
    text-align: center;

    strong {
      font-weight: $font-weight-medium;
    }

    @include media-from(xs) {
      margin-bottom: 1.5rem;
      font-size: $font-size-24;
    }
  }

  p {
    margin: 0;
    font-size: $font-size-16;
    line-height: 1.55;
    text-align: center;
    margin-bottom: 1rem;

    @include media-from(xs) {
      font-size: $font-size-18;
      margin-bottom: 1.75rem;
    }
  }
}

.button-wrapper {
  @include media-from(sm) {
    display: flex;
    justify-content: center;

    span {
      font-size: $font-size-16;
      margin-right: 0;
    }
  }
}

.reset-button {
  margin-bottom: 1rem;

  @include media-from(sm) {
    margin: 0 1rem 0 0;
  }
}
