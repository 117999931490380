@import '../../styles/index';

.wrapper {
  z-index: $index-promo-banner;
  position: relative;
  pointer-events: none;

  @include media-from(md) {
    position: fixed;
    bottom: 0;
    max-width: 1440px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.main {
  background-color: $neutral-0;
  border-radius: 3px 3px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 0 27px 0 rgba(0, 0, 0, 0.12);
  pointer-events: all;

  @include media-to(md) {
    transition: transform $duration-default;
    line-height: 1rem;

    &.expanded {
      transform: none;
    }

    &.hidden {
      transform: translateY(64px);
    }

    &.visible {
      transform: none;
    }
  }

  @include media-from(md) {
    transition: transform $duration-default;
    position: absolute;
    right: 2.5rem;
    bottom: 0;
    min-width: 300px;
    max-width: 350px;

    &.expanded {
      transform: translateY(0);
    }

    &.hidden {
      transform: translateY(calc(100% + 64px));
    }

    &.visible {
      transform: translateY(calc(100% - 64px));
    }
  }
}

.rotated-wrapper {
  @include media-to(md) {
    position: fixed;
    transform-origin: bottom left;
    right: 0;
    bottom: 6rem;
    transform: translateX(100%) rotate(-90deg);
    pointer-events: none;
  }
}

.banner-headline {
  position: relative;
  color: $neutral-12;
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
  line-height: 2rem;
  user-select: none;
  white-space: nowrap;
}

.banner {
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.banner-icon {
  color: $surrogate-9;
  margin-right: 1.125rem;
}

.banner-close {
  color: $neutral-8;
  display: flex;
  z-index: 2;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.content {
  padding: 2rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $neutral-0;
  max-width: 450px;
  position: relative;
  border-radius: $border-radius-small;
  margin: 0 auto;

  @include media-from(md) {
    margin: 0;
  }
}

.content-headline {
  position: relative;
  color: $neutral-12;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
  line-height: 1.75rem;
  user-select: none;
  text-align: center;
  margin-bottom: 0.75rem;
}

.content-description {
  font-size: $font-size-16;
  font-weight: $font-weight-light;
  color: $neutral-12;
  text-align: center;
  margin-bottom: 1.5rem;
}

.content-icon {
  color: $surrogate-9;
  margin-bottom: 1rem;
}

.headline-wrapper {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  @include media-from(md) {
    margin-right: 1rem;
  }
}

.lightbox-close {
  color: $surrogate-9;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;

  @include media-from(md) {
    display: none;
  }
}

.collapse-icon {
  color: $surrogate-9;

  &:hover {
    cursor: pointer;
  }
}

.button {
  margin-bottom: 0.75rem;
}

.divider {
  margin: 0 1rem;
  border-top: none;
  border-bottom: 1px solid $neutral-7;
}

.hidden-on-mobile {
  display: none;

  @include media-from(md) {
    display: block;
  }
}

.lightbox-wrapper {
  @include media-from(md) {
    display: none;
  }
}

.mobile-content-wrapper {
  width: 100%;
  margin: 0 1rem;
}
