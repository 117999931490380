@import '../../styles/index';

.base {
  max-width: 43.75rem; // 700px;
}

.wrapper,
.base,
.author {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.quote {
  font-size: $font-size-24;
  line-height: 1.25;
  font-weight: $font-weight-bold;
  text-align: center;
  margin-bottom: 0.5rem;

  @include media-from(sm) {
    margin-bottom: 1rem;
    font-size: $font-size-44;
  }

  @extend %text-linear-gradient;
}

.author-name {
  font-size: $font-size-18;
  line-height: 1.5;
  font-weight: $font-weight-bold;

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}

.author-job {
  color: $neutral-12;
  font-weight: $font-weight-light;
  font-size: $font-size-14;
  line-height: 1.25;

  @include media-from(sm) {
    font-size: $font-size-16;
  }
}

.icon {
  margin-bottom: 0.25rem;
  width: 3.125rem;

  @include media-from(sm) {
    margin-bottom: 0.5rem;
    width: 4.5rem;
  }
}
