@import '../../../styles/index';

.base {
  display: flex;
  flex-direction: column;

  @include media-from(xs) {
    flex-direction: row;
  }

  & > :not(:last-child) {
    @include media-from(xs) {
      margin-right: 3.5rem;
    }
  }
}
