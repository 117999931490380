@import '../../styles/index';

.layout,
.header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;

  & > main {
    width: 100%;
  }
}

.layout {
  min-height: 100vh;
  align-self: stretch;
  width: 100%;

  &.has-border {
    @include media-from(lg) {
      border-left: $section-border-width solid $neutral-0;
      border-right: $section-border-width solid $neutral-0;
    }
  }
}

.full-width {
  max-width: 100%;
}

.default {
  max-width: $layout-max-width;
}

.white {
  background-color: $neutral-0;
}

.gradient {
  background-image: $gradient-enercity;
}
